* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Gill Sans, sans-serif;
  font-size: 20px;
  color: white;
  background: linear-gradient(
      170deg,
      rgba(49, 57, 73, 0.8) 20%,
      rgba(49, 57, 73, 0.5) 20%,
      rgba(49, 57, 73, 0.5) 35%,
      rgba(41, 48, 61, 0.6) 35%,
      rgba(41, 48, 61, 0.8) 45%,
      rgba(31, 36, 46, 0.5) 45%,
      rgba(31, 36, 46, 0.8) 75%,
      rgba(49, 57, 73, 0.5) 75%
    ),
    linear-gradient(
        45deg,
        rgba(20, 24, 31, 0.8) 0%,
        rgba(41, 48, 61, 0.8) 50%,
        rgba(82, 95, 122, 0.8) 50%,
        rgba(133, 146, 173, 0.8) 100%
      )
      #313949;
}

html {
  scroll-behavior: smooth;
}

.center {
  text-align: center;
}

h3 {
  text-align: center;
  /* margin-bottom: 20px; */
}

h4 {
  font-weight: bold;
}

/*------------ Squiggle link (Work Section)-------------*/
svg {
  width: 100%;
}

.squiggle {
  animation: shift 1s linear infinite;
}

@keyframes shift {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-20px);
  }
}

a {
  background: linear-gradient(to bottom, var(--mainColor) 0%, var(--mainColor) 100%);
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 3px 3px;
  color: rgb(149, 165, 238);
  text-decoration: none;
}

a.specialeffects:hover {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift 1s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23ff9800' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-position: 0 100%;
  background-size: auto 6px;
  background-repeat: repeat-x;
  text-decoration: none;
  color: rgb(255, 255, 255);
}

/*------------ Squiggle link Ends-------------*/

/* ---------------------------------------------------------------- */
/* -------------------------- Navbar ------------------------------ */
/* ---------------------------------------------------------------- */
.navbar {
  position: fixed;
  top: 0px;
  width: 100%;
  padding: 0 0 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(54, 52, 52);
  color: white;
  box-shadow: 0px 5px 10px rgb(175, 174, 179);
  z-index: 10;
}

.author {
  margin: 5px;
}

.author a:hover {
  color: transparent;
}

.author a:visited {
  color: transparent;
}

.navbar-links ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-links li {
  list-style: none;
}

.navbar-links li a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 15px;
  display: block;
}

.navbar-links li:hover {
  background-color: rgb(117, 110, 110);
}

/* .toggle {
  position: relative;
  width: 60px;
  height: 60px;
  background-size: 30px;
  background-position: center;
}  */

.toggle-button {
  position: absolute;
  /* top: 12px; */
  top: 20px;
  right: 25px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  cursor: pointer;
}

.toggle-button .bar {
  height: 3px;
  width: 40px;
  background-color: white;
}

@media (max-width: 700px) {
  .toggle-button {
    display: flex;
  }
  .navbar-links {
    display: none;
    width: 100%;
  }
  .navbar {
    flex-direction: column;
    height: 60px;
    padding-top: 10px;
    align-items: center;
  }
  .navbar-links ul {
    width: 100%;
    flex-direction: column;
  }
  .navbar-links li {
    text-align: center;
  }
  .navbar-links li a {
    padding: 0.5rem 1rem;
  }
  .navbar-links.active {
    display: flex;
  }
}

/* ---------------------------------------------------------------- */
/* ------------------------- Home ----------------------------------*/
/* ---------------------------------------------------------------- */
video {
  position: fixed;
  top: 50%;
  left: 50%;
  /* z-index: -100; */
  /* min-width: 100%; */
  /* min-height: 100%; */
  /* width: auto;
  height: auto; */
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.showcase {
  position: relative;
  top: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  color: white;
  z-index: 100;
  transition: 0.5;
}

.showcase video {
  position: absolute;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 0.9;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgb(134, 91, 175); */
  mix-blend-mode: overlay;
  animation: changeBackgroundColor 7s infinite;
}

/* ------- For abstract --------*/
@keyframes changeBackgroundColor {
  0% {
    background-color: #6c83af;
  }
  25% {
    background-color: #9961ce;
  }
  50% {
    background-color: #c2bebe;
  }
  75% {
    background-color: #ac8751;
  }
  100% {
    background-color: #6c83af;
  }
}

/* ------ For showcaselight ------- */
/* @keyframes changeBackgroundColor {
  0% {
    background-color: #69a6e4;
  }
  30% {
    background-color: #b8cf79;
  }
  60% {
    background-color: #67a0bd;
  }
  75% {
    background-color: #b38edd;
  }
  100% {
    background-color: #5489eb;
  }
} */

.glow {
  animation: glow 0.5s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px rgb(128, 151, 207), 0 0 20px rgb(111, 133, 206), 0 0 30px #4d5fc7,
      0 0 40px #405fc4, 0 0 50px #2f55d1, 0 0 60px #324fad, 0 0 70px #1858a0;
  }
  to {
    text-shadow: 0 0 20px rgb(163, 209, 193), 0 0 30px #2b814f, 0 0 40px #23976b, 0 0 50px #136646,
      0 0 60px #167038, 0 0 70px #2e6d37, 0 0 80px #1f5f51;
  }
}

.hometext {
  position: relative;
  z-index: 10;
}

.hometext h2 {
  font-size: 4em;
  font-weight: 700px;
  line-height: 1em;
}

/* .hometext h3 {
  font-size: 2em;
  font-weight: 200;
  line-height: 1em;
} */

/* For if paragraphs on homepage */
/* .hometext p {
  font-size: 1.1em;
  margin: 20px 0;
  font-weight: 400;
  max-width: 700px;
} */

.hometext a {
  position: relative;
  padding: 20px 50px;
  width: 200px;
  display: block;
  text-decoration: none;
  overflow: hidden;
  /* border-radius: 80px; */ /* --- Option1: Button --- */
  border-radius: 20px 20px 100px 100px; /* --- Option2: Bowl ------*/
  text-align: center;
  margin: auto;
}

a span {
  position: relative;
  z-index: 1;
  color: rgb(255, 255, 255);
  font-size: 20px;
}

a .liquid {
  position: absolute;
  left: 0;
  /* top: -70px; */ /* --- Liquid Fill Option1 --- */
  top: -80px; /* --- Liquid Fill Option2 --- */
  width: 200px;
  height: 200px;
  background: #274ecf;
  box-shadow: inset 0 0 60px 0 rgb(255, 255, 255);
  transition: 1.5s;
}

a:hover .liquid {
  top: -120px;
  background: rgb(14, 95, 55);
}

a .liquid:before,
a .liquid:after {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -75%);
}

a .liquid:before {
  border-radius: 45%;
  background: rgba(20, 20, 20, 1);
  animation: animate 8s linear infinite;
}

a .liquid:after {
  border-radius: 40%;
  background: rgba(20, 20, 20, 0.5);
  animation: animate 10s linear infinite;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

h1 span {
  margin: 0;
  padding: 0;
  font-size: 50px;
  animation: animate2 5.5s linear infinite;
  color: transparent;
  user-select: none
}
h1 span:nth-child(1) {
  animation-delay: 0s;
}
h1 span:nth-child(2) {
  animation-delay: 0.25s;
}
h1 span:nth-child(3) {
  animation-delay: 0.5s;
}
h1 span:nth-child(4) {
  animation-delay: 0.75s;
}
h1 span:nth-child(5) {
  animation-delay: 1s;
}
h1 span:nth-child(6) {
  animation-delay: 1.25s;
}
h1 span:nth-child(7) {
  animation-delay: 1.5s;
}
h1 span:nth-child(9) {
  animation-delay: 1.75s;
}
h1 span:nth-child(10) {
  animation-delay: 2s;
}
h1 span:nth-child(11) {
  animation-delay: 2.25s;
}
h1 span:nth-child(13) {
  animation-delay: 2.5s;
}
h1 span:nth-child(14) {
  animation-delay: 2.75s;
}
h1 span:nth-child(15) {
  animation-delay: 3s;
}
h1 span:nth-child(16) {
  animation-delay: 3.25s;
}
h1 span:nth-child(17) {
  animation-delay: 3.5s;
}
h1 span:nth-child(18) {
  animation-delay: 3.75s;
}
h1 span:nth-child(19) {
  animation-delay: 4s;
}
h1 span:nth-child(20) {
  animation-delay: 4.25s;
}
h1 span:nth-child(21) {
  animation-delay: 4.5s;
}
h1 span:nth-child(22) {
  animation-delay: 4.75s;
}
h1 span:nth-child(23) {
  animation-delay: 5s;
}
/* h1 span:nth-child(23) {
  animation-delay: 5.25s;
} */
/* h1 span:nth-child(23) {
  animation-delay: 3.5s;
}
h1 span:nth-child(24) {
  animation-delay: 3.5s;
}
h1 span:nth-child(24) {
  animation-delay: 3.5s;
}
h1 span:nth-child(24) {
  animation-delay: 3.5s;
}
h1 span:nth-child(24) {
  animation-delay: 3.5s;
}
h1 span:nth-child(24) {
  animation-delay: 3.5s;
} */

@keyframes animate2 {
  0%,
  100% {
    color: rgb(255, 255, 255);
    filter: blur(0px);
    text-shadow: 0 0 10px #00b3ff, 0 0 20px #00b3ff, 0 0 40px #00b3ff, 0 0 60px #00b3ff,
      0 0 80px #00b3ff, 0 0 100px #00b3ff, 0 0 120px #00b3ff, 0 0 140px #00b3ff, 0 0 160px #00b3ff,
      0 0 180px #00b3ff, 0 0 200px #00b3ff, 0 0 300px #00b3ff, 0 0 400px #00b3ff;
  }
  5%,
  95% {
    color: rgb(0, 0, 0);
    filter: blur(0px);
    text-shadow: 0px;
  }
}

/* Smile Delay */
@-moz-keyframes showEffect {
  0% { display: none; visibility: hidden; }
  100% { display: block; visibility: block;  }
}
@-webkit-keyframes showEffect {
  0% { display: none; visibility: hidden; }
  100% { display: block; visibility: block;  }

}
@keyframes showEffect {
  0% { display: none; visibility: hidden; }
  100% { display: block; visibility: block;  }
}

.css_only {
  -moz-animation-name: showEffect;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: ease-in;
  -moz-animation-duration: 2.3s;

  -webkit-animation-name: showEffect;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
  -webkit-animation-duration: 2.3s;

  animation-name: showEffect;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2.3s;
}
.showEffect {
  user-select: none;
}
/* Smile Delay Ends */

.subText{
  /* z-index: 1001; */
  position: relative;
  text-align: center;
  padding-top: 1rem;
  user-select: none;
}




.animate2 {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 800px) {
h1 span {
  font-size: 30px
}
}

/* Audio */
/* #musicon {
  width: 55px;
  cursor: pointer;
}

#musicon {
  position: absolute;
  top: -220px;
  right: -475px;
  margin-left: auto;
  margin-right: auto;
  width: 55px;
  cursor: pointer;
}

#music-text {
  position: absolute;
  top: -160px;
  right: -470px;
} */

/* -------------- Heading Divider -------------- */
.divider {
  display: flex;
  align-items: center;
  text-align: center;
  width: 95%;
  margin: auto;
}

/* To show the lines on right and left sides of the text */
.divider::after,
.divider::before {
  content: "";
  border: 1px solid rgb(255, 255, 255);
  flex: 1;
}

/* Space on left and right sides of text */
.divider:not(:empty)::before {
  margin-right: 0.25em;
}

.divider:not(:empty)::after {
  margin-left: 0.25em;
}

/* ---------Wow Emoji Begins -------- */
.wow {
  height: 150px;
  width: 150px;
  background-color: #fbba08;
  border-radius: 50%;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  animation: wow 3s linear infinite;
}

@keyframes wow {
  15% {
    transform: translateX(20px);
  }
  70% {
    transform: translateX(-20px);
  }
  95% {
    /* Smooth wow */
    /* /* 75% {   */
    transform: translateX(0px);
  }
}

.face {
  position: absolute;
  height: inherit;
  width: inherit;
  animation: face 3s linear infinite;
}
@keyframes face {
  15%,
  25% {
    transform: rotate(10deg) translateX(30px);
  }
  45%,
  65% {
    transform: rotate(-10deg) translateX(-30px);
  }
  75%,
  100% {
    transform: rotate(0deg) translateX(0px);
  }
}
.eyebrows {
  position: absolute;
  height: 6px;
  width: 6px;
  /* background-color: black; */
  left: 70px;
  top: 40px;
  border-radius: 50%;
  box-shadow: -18px 0 0 0 #000, -32px 0 0 0 #000, 18px 0 0 0 #000, 32px 0 0 0 #000;
  animation: brow 3s linear infinite;
}
@keyframes brow {
  15%,
  65% {
    top: 25%;
  }
  75%,
  100%,
  0% {
    top: 15px;
  }
}
.eyebrows::after {
  content: "";
  position: absolute;
  height: 20px;
  width: 24px;
  border: 6px solid transparent;
  box-sizing: border-box;
  border-radius: 50%;
  border-top-color: black;
  top: -3px;
  margin-left: 16px;
}
.eyebrows::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 24px;
  border: 6px solid transparent;
  box-sizing: border-box;
  border-radius: 50%;
  border-top-color: black;
  top: -3px;
  margin-left: -34px;
}
.eyes {
  position: absolute;
  height: 24px;
  width: 16px;
  /* background-color: black; */
  top: 50px;
  left: 65px;
  border-radius: 50%;
  box-shadow: 25px 0 0 0 black, -25px 0 0 0 black;
}
.mouth {
  position: absolute;
  height: 45px;
  width: 30px;
  background-color: black;
  left: 60px;
  top: 60%;
  border-radius: 50%;
  animation: mouth 3s linear infinite;
}
@keyframes mouth {
  10%,
  30% {
    height: 20px;
    width: 20px;
    left: 50px;
  }
  50%,
  70% {
    height: 40px;
    width: 30px;
    left: 45px;
  }
  75%,
  100% {
    height: 50px;
  }
}

/* ---------Wow Emoji Ends -------- */

/* ---------------------------------------------------------------- */
/* ----------------------- Projects Section ----------------------- */
/* ---------------------------------------------------------------- */

.flex-wrapper-project {
  /* background: linear-gradient(to left bottom, #717499, #6e7392, #6c728a, #6b7182, #6b6f7a, 
  #6a7079, #6b7177, #6d7275, #6d7679, #6e797c, #6f7d7e, #70817f); */
  padding-bottom: 10px;
  padding-top: 100px;
}
.project-container p {
  text-align: center;
  color: white;
}

.wow2 {
  height: 150px;
  width: 150px;
  background-color: #fbba08;
  border-radius: 50%;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  animation: wow2 3s linear infinite;
}

@keyframes wow2 {
  15% {
    transform: translateX(20px);
  }
  70% {
    transform: translateX(-20px);
  }
  /*95% { */ /*Smooth wow */
  75% {
    /*Shocked wow */
    transform: translateX(0px);
  }
}

.eyebrows2 {
  position: absolute;
  height: 6px;
  width: 6px;
  left: 70px;
  top: 40px;
  border-radius: 50%;
  box-shadow: -18px 0 0 0 #000, -32px 0 0 0 #000, 18px 0 0 0 #000, 32px 0 0 0 #000;
  animation: brow2 3s linear infinite;
}
@keyframes brow2 {
  15%,
  65% {
    top: 25%;
  }
  75%,
  100%,
  0% {
    top: 15px;
  }
}
.eyebrows2::after {
  content: "";
  position: absolute;
  height: 20px;
  width: 24px;
  border: 6px solid transparent;
  box-sizing: border-box;
  border-radius: 50%;
  border-top-color: black;
  top: -3px;
  margin-left: 13px;
}
.eyebrows2::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 24px;
  border: 6px solid transparent;
  box-sizing: border-box;
  border-radius: 50%;
  border-top-color: black;
  top: -3px;
  margin-left: -37px;
}

.project-container {
  display: flex;
  flex-wrap: wrap;
  /* background-color: rgb(72, 85, 109); */
  align-items: flex-start;
  justify-content: center;
  /* padding-bottom: 150px; */
  /* padding-top: 100px; Omit in fav of flex-wrapper */
}

/* Individual project containers */
.project-container > div {
  /* background-color: rgb(109, 127, 138); */
  width: 600px;
  /* min-height: 500px; */
  height: auto;
  margin: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  box-shadow: inset 0 0 5px 5px rgb(157, 165, 224);
  border-radius: 15px;
  /* background-image: url("./components/pages/background1.jpg");
  background-size: cover;
  background-position: center center;
  background-attachment: fixed; */
}

#describe-section {
  /* background-color: rgb(109, 127, 138); */
  width: 1225px;
  /* min-height: 220px; */
  height: auto;
  margin: 10px;
  padding-top: 10px;
  text-align: center;
  box-shadow: none;
}

.project-img {
  width: 90%;
  height: auto;
  border-radius: 10px;
}

/* Media queries for project container */
@media screen and (max-width: 1257px) {
  .project-container > div {
    /* background-color: rgb(109, 127, 138); */
    width: 550px;
  }
}

@media screen and (max-width: 1160px) {
  .project-container > div {
    width: 500px;
  }
}

@media screen and (max-width: 1080px) {
  .project-container > div {
    width: 450px;
  }
}

@media screen and (max-width: 960px) {
  .project-container > div {
    width: 700px;
  }
}
/* Media queries for project's describe-section */
@media screen and (max-width: 1257px) {
  #describe-section {
    width: 1120px;
  }
}
@media screen and (max-width: 1160px) {
  #describe-section {
    width: 1020px;
  }
}

@media screen and (max-width: 1080px) {
  #describe-section {
    width: 920px;
  }
}

@media screen and (max-width: 960px) {
  #describe-section {
    width: 700px;
  }
}

/* ---------------------------------------------------------------- */
/* ----------------------- Contact Section -------------------------*/
/* ---------------------------------------------------------------- */

.contact-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.contact-container > div {
  width: 1000px;
  height: auto;
  margin: 10px;
  padding: 20px 5px 10px 5px;
  text-align: center;
  box-shadow: inset 0 0 5px 5px rgb(157, 165, 224);
  border-radius: 15px;
}



/* .contact-row {
  display: -webkit-flex;
  display: flex;
  background-color: rgb(211, 88, 7);
  margin: 20px;
  padding: 20px;
} */

/* --------------------------- Old Form ----------------------------*/
/* #h1contact-form {
  text-align: center;
  padding-top: 5px;
} */

/* .contact-column {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: rgb(72, 85, 109);
  padding: 20px;
  margin: 10px;
  margin-bottom: 200px;
}

.contactform-container {
  border-radius: 10px;
  background-color: rgb(109, 127, 138);
  padding: 20px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid white;
  border-radius: 4px;
  resize: vertical;
  font-size: 16px;
}

#email {
  width: 100%;
  padding: 5px;
  border: 1px solid white;
  border-radius: 4px;
  resize: vertical;
  font-size: 16px;
}

label {
  padding: 1px 12px 10px;
  display: inline-block;
}

input[type="submit"] {
  background-color: green;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  width: 100px;
  float: right;
}

input[type="submit"]:hover {
  background-color: rgb(19, 116, 51);
}

input[type="reset"] {
  background-color: rgb(209, 70, 35);
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100px;
  float: left;
  margin-left: 25%;
}

input[type="reset"]:hover {
  background-color: rgb(145, 38, 38);
} */

/* Responsive layout - when the screen is less than 850px wide, 
the two columns stack on top of each other instead of next to each other */

/* -------------------Media Query for Old Form ------------------------- */
/* @media screen and (max-width: 850px) {
  .col-25,
  .col-75 {
    width: 100%;
    margin-top: 0;
  }
}

@media screen and (max-width: 850px) {
  input[type="reset"] {
    margin-left: auto;
  }
}

.contact-background {
  background: transparent;
}

.contact-header {
  margin-bottom: -50px;
}

.contact-paragraph {
  margin-bottom: -30px;
} */

/* ====================== Sections End =============================*/

/* ---------------------------------------------------------------- */
/* -------------------------- Footer ------------------------------ */
/* ---------------------------------------------------------------- */

/* footer {
  text-align: center;
  background-color: rgb(165, 161, 161);
  bottom: 0;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center; 
} */

footer {
  position: relative;
  width: 100%;
  background: #3586ff;
  min-height: 100px;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 200px;
}

footer .social_icon,
footer .menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

footer .social_icon li,
footer .menu li {
  list-style: none;
}

footer .social_icon li a {
  font-size: 40px;
  color: white;
  margin: 0 10px;
  display: inline-block;
  transition: 0.4s;
}

footer .social_icon li a:hover {
  transform: translateY(-10px);
  color: black;
}

footer .menu li a {
  font-size: 20px;
  color: white;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
}

footer .menu li a:hover {
  opacity: 1;
}

footer p {
  color: white;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
  font-style: italic;
  font-size: 1.1em;
}

footer .wave {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(./wave.png);
  background-size: 1000px 100px;
}

footer .wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWave 8s linear infinite;
  animation-delay: 1s;
  /* animation-duration: 4s; */
}

footer .wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animateWave2 7s linear infinite;
}

footer .wave#wave3 {
  z-index: 1000;
  opacity: 0.3;
  bottom: 15px;
  animation: animateWave 6s linear infinite;
}

footer .wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animateWave2 5s linear infinite;
  animation-delay: 1s;
  /* animation-duration: 5s; */
}

@keyframes animateWave {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-position-x: 0px;
  }
}

@keyframes animateWave2 {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 1000px;
  }
}

/* ---------------------- Media Query Begins ------------------*/
@media (max-width: 1200px) {
  a .liquid {
    position: absolute;
    left: 0;
    top: -70px;
    /* top: -70px; */
    width: 200px;
    height: 200px;
    background: #a93fd3;
    box-shadow: inset 0 0 50px 0 rgb(0, 0, 0);
    transition: 1.5s;
  }

  a:hover .liquid {
    top: -120px;
    background: #c4c125;
  }
}

/* .hometext h2 {
  font-size: 60px;
} */

.hometext h3 {
  font-size: 40px;
}

@media (max-width: 1000px) {
  a .liquid {
    position: absolute;
    left: 0;
    top: -60px;
    width: 200px;
    height: 200px;
    background: #eeea14;
    box-shadow: inset 0 0 50px 0 rgb(0, 0, 0);
    transition: 1.5s;
  }

  a:hover .liquid {
    top: -120px;
    background: #b128e7;
  }
}

@media (max-width: 800px) {
  .hometext h1 {
    font-size: 2em;
  }

  /* Liquid */
  a span {
    position: relative;
    z-index: 1;
    color: rgb(255, 255, 255);
    font-size: 20px;
  }

  a:hover span {
    position: relative;
    z-index: 1;
    color: rgb(255, 255, 255);
    font-size: 20px;
  }

  a .liquid {
    position: absolute;
    left: 0;
    top: -50px;
    width: 200px;
    height: 200px;
    background: rgb(211, 62, 62);
    box-shadow: inset 0 0 50px 0 rgb(0, 0, 0);
    transition: 1.5s;
  }

  a:hover .liquid {
    top: -120px;
    background: #274ecf;
  }

  a .liquid:before,
  a .liquid:after {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    top: 0;
    left: 50%;
    transform: translate(-50%, -75%);
  }

  a .liquid:before {
    border-radius: 45%;
    background: rgba(20, 20, 20, 1);
    animation: animate 8s linear infinite;
  }

  a .liquid:after {
    border-radius: 40%;
    background: rgba(20, 20, 20, 0.5);
    animation: animate 10s linear infinite;
  }

  @keyframes animate {
    0% {
      transform: translate(-50%, -75%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -75%) rotate(360deg);
    }
  }

  /* h3 */
  h1 {
    font-size: 18px;
  }
  h3 span {
    margin: 0;
    padding: 0;
    animation: animate2 3.5s linear infinite;
    color: transparent;
  }
  h3 span:nth-child(1) {
    animation-delay: 0s;
  }
  h3 span:nth-child(2) {
    animation-delay: 0.25s;
  }
  h3 span:nth-child(3) {
    animation-delay: 0.5s;
  }
  h3 span:nth-child(4) {
    animation-delay: 0.5s;
  }
  h3 span:nth-child(5) {
    animation-delay: 0.75s;
  }
  h3 span:nth-child(6) {
    animation-delay: 1s;
  }
  h3 span:nth-child(7) {
    animation-delay: 1.25s;
  }
  h3 span:nth-child(8) {
    animation-delay: 1.5s;
  }
  h3 span:nth-child(9) {
    animation-delay: 1.75s;
  }
  h3 span:nth-child(10) {
    animation-delay: 2s;
  }
  h3 span:nth-child(11) {
    animation-delay: 2.25s;
  }
  h3 span:nth-child(12) {
    animation-delay: 2.5s;
  }
  h3 span:nth-child(13) {
    animation-delay: 2.75s;
  }
  h3 span:nth-child(14) {
    animation-delay: 3s;
  }

  @keyframes animate2 {
    0%,
    100% {
      color: rgb(0, 0, 0);
      filter: blur(0px);
      text-shadow: 0 0 10px #00b3ff, 0 0 20px #00b3ff, 0 0 40px #00b3ff, 0 0 60px #00b3ff,
        0 0 80px #00b3ff, 0 0 100px #00b3ff, 0 0 120px #00b3ff, 0 0 140px #00b3ff, 0 0 160px #00b3ff,
        0 0 180px #00b3ff, 0 0 200px #00b3ff, 0 0 300px #00b3ff, 0 0 400px #00b3ff;
    }
    5%,
    95% {
      color: rgb(255, 255, 255);
      filter: blur(0px);
      text-shadow: 0px;
    }
  }
}

/* Go to top Button */
#goTopBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 15px;
  border: none;
  outline: none;
  background-color: rgb(106, 194, 55);
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 50%;
  opacity: 0.5;
  z-index: 1001;
}
#goTopBtn:hover {
  background-color: rgb(25, 107, 53);
  opacity: 1;
}
/* -------------------- Media Query Ends ------------------------*/
