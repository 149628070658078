/* ---------------------------------------------------------------- */
/* ----------------------About Me Section --------------------------*/
/* ---------------------------------------------------------------- */

.about-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  padding-top: 20px;
  min-height: 600px;
}

.about-container > div {
  width: 600px;
  min-height: 550px;
  margin: 10px;
  padding: 10px;
  text-align: left;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 5px #292828;
  background: linear-gradient(
      170deg,
      rgba(49, 57, 73, 0.8) 20%,
      rgba(49, 57, 73, 0.5) 20%,
      rgba(49, 57, 73, 0.5) 35%,
      rgba(41, 48, 61, 0.6) 35%,
      rgba(41, 48, 61, 0.8) 45%,
      rgba(31, 36, 46, 0.5) 45%,
      rgba(31, 36, 46, 0.8) 75%,
      rgba(49, 57, 73, 0.5) 75%
    ),
    linear-gradient(
        45deg,
        rgba(38, 46, 59, 0.8) 0%,
        rgba(60, 71, 90, 0.8) 50%,
        rgba(82, 95, 122, 0.8) 50%,
        rgba(133, 146, 173, 0.8) 100%
      )
      #313949;
}

.flex-wrapper-about {
  padding-bottom: 10px;
  padding-top: 100px;
}

.tab {
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  box-shadow: 5px 5px 15px 5px #292828;
}

/* Style the buttons inside the tab */
.tab button {
  display: block;
  background-color: inherit;
  color: rgb(255, 255, 255);
  padding: 22px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
  border-radius: 5px;
  text-align: center;
  box-shadow: inset 5px 5px 15px 5px #292828;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
  color: black;
}

/* Create an active/current "tab button" class */
.tab button.active {
  background-color: #ccc;
  color: black;
}

/* Style the tab content */
.tabcontent {
  padding: 20px 12px 0 30px;
  border: 1px solid #ccc;
  min-height: 100%;
  border: none;
}

.tabs-container {
  flex-basis: 100%;
  margin: 0 20px 0 20px;
  overflow: hidden;
}
.tabs-start {
  float: left;
  width: 15%;
  overflow: auto;
}
.tabs-end {
  float: left;
  width: 85%;
}

.hide {
  display: none;
}

/* Adjust size to fit rest of the sections */
@media screen and (min-width: 1257px) {
  .tabs-container {
    flex-basis: 1225px;
  }
}

@media screen and (max-width: 1257px) {
  .about-container > div {
    width: 550px;
  }
}
@media screen and (max-width: 1160px) {
  .about-container > div {
    width: 500px;
  }
}

@media screen and (max-width: 1080px) {
  .about-container > div {
    width: 450px;
  }
}

@media screen and (max-width: 960px) {
  .about-container > div {
    width: 700px;
  }
  .tab button {
    display: grid;
    flex-direction: row;
    padding: 4px 4px;
    align-items: center;
  }

  .tabs-start {
    float: top;
    width: 100%;
  }
  .tabs-end {
    float: left;
    width: 100%;
  }
  .tab-flexcontainer {
    display: flex;
    gap: 5px;
  }
}

/*--------------- Table styling ----------------- */
table {
  width: 100%;
  font-size: 1.2rem;
}
/* Zebra striping */
tr:nth-of-type(odd) {
  background: rgb(42, 50, 77);
}

td {
  padding: 2px;
  border: 1px solid #ccc;
  text-align: left;
}

.td-year {
  text-align: center;
}

/* ------ Tri-color Ring Begins -------*/
.flex-wrapper-rings {
  padding-top: 110px;
  margin-top: -14px;
}

.feature-container {
  position: relative;
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

/* Ring 1 */
.feature-container .ring {
  position: relative;
  width: 150px;
  height: 150px;
  margin: -30px;
  border-radius: 50%;
  border: 4px solid transparent;
  /* border-top: 4px solid #24ecff; */
  border-top: 4px solid #24ecff;
  animation: animateRing 4s linear infinite;
}
@keyframes animateRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.feature-container .ring::before {
  content: "";
  position: absolute;
  top: 12px;
  right: 12px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  /* background: #24ecff; */
  background: #24ecff;
  box-shadow: 0 0 0 5px #24ecff33, 0 0 0 10px #24ecff22, 0 0 0 20px #24ecff11, 0 0 20px #1e137c,
    0 0 50px #9a22be;
}

/* Ring 2 */
.feature-container .ring:nth-child(2) {
  animation: animateRing2 4s linear infinite;
  animation-delay: -1s;
  border-top: 4px solid transparent;
  border-left: 4px solid #93ff2d;
}
.feature-container .ring:nth-child(2)::before {
  content: "";
  position: absolute;
  top: initial;
  bottom: 12px;
  left: 12px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: rgb(236, 240, 16);
  box-shadow: 0 0 0 5px #93ff2d33, 0 0 0 10px #24ecff22, 0 0 0 20px #24ecff11,
    0 0 20px rgb(236, 240, 16), 0 0 50px rgb(236, 240, 16);
}
@keyframes animateRing2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Ring 3 */
.feature-container .ring:nth-child(3) {
  animation: animateRing2 4s linear infinite;
  animation-delay: -3s;
  position: absolute;
  top: -66.66px;
  border-top: 4px solid transparent;
  border-left: 4px solid #e41cf8;
}
.feature-container .ring:nth-child(3)::before {
  content: "";
  position: absolute;
  top: initial;
  bottom: 12px;
  left: 12px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #e41cf8;
  box-shadow: 0 0 0 5px #e41cf833, 0 0 0 10px #e41cf822, 0 0 0 20px #e41cf811, 0 0 20px #e41cf8,
    0 0 50px #e41cf8;
}

/* .feature-container p {
    position: absolute;
    color: white;
    font-size: 20px;
    bottom: -80px;
  } */
/* ------ Tri-color Ring Ends -------*/

/* ------ Rotate Box Begins -------*/
/* Rotate Box for Skills Tab */

/* HTML icon */
/* .box {
    border-style: solid;
    border-width: 1px;
    display: block;
    width: 100px;
    height: 100px;
    background-color: #7c50b4;
    transition: width 2s, height 2s, background-color 2s, transform 2s;
  }
  
  .box:hover {
    background-color: #36798d;
    width: 200px;
    height: 200px;
    transform: rotate(180deg);
    background: url(./assets/pause.png);
  } */

.skills-feature-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.box-skills-html {
  border-style: solid;
  border-width: 1px;
  display: block;
  width: 120px;
  height: 120px;
  background: url(./assets/html-logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  transition: width 2s, height 2s, background-color 2s, transform 2s;
  margin: 0 15px 30px 15px;
}

.box-skills-html:hover {
  background-color: rgb(219, 94, 44);
  /* width: 182px;
  height: 180px; */
  background-size: cover;
  background-repeat: no-repeat;
}
.rotate-text-html {
  text-align: center;
  background-color: rgb(82, 80, 97);
  font-size: 18px;
}
.box-skills-html .rotate-text-html {
  transition: transform 1s;
}

.box-skills-html:hover .rotate-text-html {
  transform: rotate(-360deg);
  transition: transform 1s;
  margin-top: -28px;
  border-style: solid;
  border-width: 1px;
  background-color: rgb(219, 94, 44);
  margin-left: -1px;
  margin-right: -1px;
  border-bottom: none;
}

/* CSS icon */
.box-skills-css {
  border-style: solid;
  border-width: 1px;
  display: block;
  width: 120px;
  height: 120px;
  background: url(./assets/css-logo.png);
  background-color: white;
  background-size: contain;
  background-repeat: no-repeat;
  transition: width 2s, height 2s, background-color 2s, transform 2s;
  border-radius: 10px;
  margin: 0 15px 30px 15px;
}

.box-skills-css:hover {
  /* width: 182px;
  height: 180px; */
  transform: rotate(360deg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: rgb(45, 101, 185);
  border-radius: 0 0 10px 10px;
}

.rotate-text-css {
  text-align: center;
  background-color: rgb(82, 80, 97);
  border-radius: 10px 10px 0 0;
  font-size: 18px;
}

.box-skills-css .rotate-text-css {
  transition: transform 1s;
  border-style: solid;
  border-width: 0 0 0px 0;
}

.box-skills-css:hover .rotate-text-css {
  transform: rotate(-360deg);
  transition: transform 2s;
  margin-top: -28px;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  background-color: rgb(45, 101, 185);
  margin-left: -1px;
  margin-right: -1px;
}

/* ---------------- JS Skill --------------- */
.box-skills-js {
  border-style: solid;
  border-width: 1px;
  display: block;
  width: 120px;
  height: 120px;
  background: url(./assets/js-logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  transition: width 2s, height 2s, background-color 2s, transform 2s;
  margin: 0 15px 60px 15px;
}

.box-skills-js:hover {
  /* width: 182px;
  height: 180px; */
  transform: rotate(180deg);
  background: url(./assets/js-logo2.png);
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 0 0 10px 10px;
  border: solid 1px rgb(255, 255, 255);
  background-color: rgb(179, 170, 48);
}

.rotate-text-js {
  text-align: center;
  background-color: rgb(82, 80, 97);
}
.box-skills-js .rotate-text-js {
  transition: transform 1s;
  border-style: solid;
  border-width: 0 0 0px 0;
  font-size: 18px;
}

.box-skills-js:hover .rotate-text-js {
  transform: rotate(-360deg);
  transition: transform 1.5s;
  margin-top: -28px;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  background-color: rgb(179, 170, 48);
  margin-left: -1px;
  margin-right: -1px;
  transform: rotate(180deg);
  border-radius: 0 0 10px 10px;
}

/* --------- Bootstrap Skill ------------ */
.box-skills-bootstrap {
  border-style: solid;
  border-width: 1px;
  display: block;
  width: 120px;
  height: 120px;
  background: url(./assets/bootstrap-logo.png);
  background-color: white;
  background-size: contain;
  background-repeat: no-repeat;
  transition: width 2s, height 2s, background-color 2s, transform 2s;
  border-radius: 10px;
  margin: 0 15px 30px 15px;
}

.box-skills-bootstrap:hover {
  /* width: 182px;
  height: 180px; */
  /* transform: rotate(360deg); */
  /* background-color: rgb(45, 101, 185); */
  border-radius: 0 0 10px 10px;
  background: url(./assets/bootstrap-logo2.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-top: 0;
  transition: 1s;
  animation: bootstrapfloat 0.5s 1s infinite ease-out alternate;
}
@keyframes bootstrapfloat {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}

.rotate-text-bootstrap {
  text-align: center;
  border-radius: 10px 10px 0 0;
  font-size: 18px;
  margin-top: -27px;
}

/* unhover effect */
.box-skills-bootstrap .rotate-text-bootstrap {
  transition: transform 1s;
  transition: 1s;
}

.box-skills-bootstrap:hover .rotate-text-bootstrap {
  /* transform: rotate(-360deg); */
  /* transition: transform 2s; */
  margin-top: -27px;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  background-color: #7044a6;
  margin-left: -1px;
  margin-right: -1px;
}

/* ---------------------- Angular Skill --------------------- */
.box-skills-angular {
  /* border-style: solid;
  border-width: 0px; */
  display: block;
  width: 120px;
  height: 120px;
  background: url(./assets/angular-logo.png);
  background-color: white;
  background-size: contain;
  background-repeat: no-repeat;
  transition: width 2s, height 2s, background-color 2s, transform 2s;
  border-radius: 10px;
  margin: 0 15px 30px 15px;
  transform: rotate(-10deg);
}

.box-skills-angular:hover {
  /* width: 182px;
  height: 180px; */
  border-radius: 0;
  background: url(./assets/angular-logo2.png);
  background-color: white;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transform: rotate(10deg);
  transition: 2s;
  animation: angularWiggle 1s 2s infinite ease-out alternate;
}
@keyframes angularWiggle {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
  /* 0% { transform: translateY(0); }
  100% { transform: translateY(-10px); } */
}

/* .rotate-text-angular {
  text-align: center;
  background-color: rgb(82, 80, 97);
  border-radius: 10px 10px 0 0;
  font-size: 19px;
  margin-top: -30px;
} */

/* .box-skills-angular .rotate-text-angular {
  transition: transform 1s;
  border-style: solid;
  border-width: 0 0 0px 0;
} */

/* .box-skills-angular:hover .rotate-text-angular {
  transform: rotate(-360deg); 
  transition: transform 2s;
  margin-top: -29px;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  background-color: #7044A6;
  margin-left: -1px;
  margin-right: -1px;
} */

.rotate-text-angular {
  text-align: center;
  background-color: transparent;
  border-radius: 10px 10px 0 0;
  font-size: 18px;
  margin-top: -30px;
}

/* /* .box-skills-angular {
  border-style: solid;
  border-width: 1px;
  display: block;
  width: 120px;
  height: 120px;
  background: url(./assets/angular-logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #46d0da;
  transition: width 2s, height 2s, background-color 2s, transform 2s;
  margin: 0 15px 30px 15px;
} */

.rotate-text-angular {
  text-align: center;
  font-size: 18px;
  margin-top: -28px;
  color: transparent;
}

.box-skills-angular:hover .rotate-text-angular {
  display: none;
}
/* Angular - JS */
.rotate-text-angular1 {
  text-align: center;
  background-color: transparent;
  font-size: 18px;
  padding: 2px;
  /* margin-top: -30px; */
}
.box-skills-angular .rotate-text-angular1 {
  transition: transform 1s;
  border-style: solid;
  border-width: 0;
  margin-top: -45px;
  color: transparent;
}
.box-skills-angular:hover .rotate-text-angular1 {
  /* transform: rotate(1440deg); */
  transform: rotate(360deg);
  transition: transform 2s;
  margin-top: -31px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border-radius: 10px 10px 0 0;
}

/* Angular - unhover reverse  */
.rotate-text-angular1-1 {
  text-align: center;
  background-color: transparent;
  font-size: 18px;
  /* margin-top: -28px; */
  border-radius: 0 0 10px 10px;
}
.box-skills-angular .rotate-text-angular1-1 {
  transition: transform 1s;
  border-style: solid;
  border-width: 0;
  margin-top: -45px;
  color: white;
}
/* unhover effect */
.box-skills-angular:hover .rotate-text-angular1-1 {
  /* transform: rotate(900deg); */
  transform: rotate(360deg);
  transition: transform 2s;
  margin-top: -28px;
  color: transparent;
}

/* Angular - Hover Top */
.rotate-text-angular2 {
  text-align: center;
  color: transparent;
}

.box-skills-angular .rotate-text-angular2 {
  transition: transform 1s;
  /* border-style: solid;
  border-width: 0; */
}

.box-skills-angular:hover .rotate-text-angular2 {
  transform: rotate(360deg);
  transition: transform 2s;
  margin-top: 102px;
  color: rgb(0, 0, 0);
  border-radius: 0 0 10px 10px;
  background-color: rgb(255, 255, 255);
}

/* -------------- React Skill ------------- */
.box-skills-react {
  border-style: solid;
  border-width: 1px;
  display: block;
  width: 120px;
  height: 120px;
  background: url(./assets/react-logo.jpg);
  background-size: contain;
  transition: width 2s, height 2s, background-color 2s, transform 2s;
  margin: 0 15px 30px 15px;
}

.box-skills-react:hover {
  /* width: 182px;
  height: 180px; */
  transform: rotate(180deg);
  background: url(./assets/react-logo-active.gif);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: none;
}

.rotate-text-react {
  text-align: center;
  /* font-size: 18px; */
  margin-top: -30px;
  color: transparent;
}

.box-skills-react:hover .rotate-text-react {
  display: none;
}
/* React - JS */
.rotate-text-react1 {
  text-align: center;
  background-color: transparent;
  /* font-size: 18px; */
  /* margin-top: -30px; */
  border-radius: 0 0 10px 10px;
}
.box-skills-react .rotate-text-react1 {
  transition: transform 1s;
  border-style: solid;
  border-width: 0;
  margin-top: -45px;
  color: transparent;
}
.box-skills-react:hover .rotate-text-react1 {
  /* transform: rotate(900deg); */
  transform: rotate(900deg);
  transition: transform 2s;
  margin-top: -30px;
  background-color: rgb(31, 32, 32);
  color: white;
}

/* React - unhover reverse  */
.rotate-text-react1-1 {
  text-align: center;
  background-color: transparent;
  font-size: 18px;
  border-radius: 0 0 10px 10px;
}
.box-skills-react .rotate-text-react1-1 {
  transition: transform 1s;
  border-style: solid;
  border-width: 0;
  margin-top: -45px;
  color: white;
}
.box-skills-react:hover .rotate-text-react1-1 {
  transform: rotate(900deg);
  transition: transform 2s;
  margin-top: -85px;
  color: transparent;
}

/* React - Hover Top */
.rotate-text-react2 {
  text-align: center;
  color: transparent;
}

.box-skills-react .rotate-text-react2 {
  transition: transform 1s;
  border-style: solid;
  border-width: 0 0 0px 0;
}

.box-skills-react:hover .rotate-text-react2 {
  transform: rotate(900deg);
  transition: transform 2s;
  margin-top: 162px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  border-radius: 10px 10px 0 0;
  background-color: rgb(31, 32, 32);
}

/* ----------- Tri-Square Begins ---------- */
.square {
  display: inline-block;
  background-image: linear-gradient(to right bottom, #777a89, #767a8f, #757a95, #74799b, #7379a1);
  height: 100px;
  width: 120px;
  padding: 1px;
  color: white;
  border-radius: 2px;
  animation: skew 2s infinite;
  animation-direction: alternate;
  margin: 10px 15px;
}

.center-square {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  flex-wrap: wrap;
  padding: 5px;
}
.square > p {
  margin-top: 30px;
}

.square:hover {
  display: inline-block;
  background-image: linear-gradient(to right bottom, #6e86aa, #6a85b1, #6783b9, #6681c0, #667fc6);
  animation: skew 2s infinite;
  animation-direction: alternate;
  color: black;
}

@keyframes skew {
  0% {
    transform: skew(10deg, 10deg);
  }
  100% {
    transform: skew(-10deg, -10deg);
  }
}
/* ----------- Tri-Square Ends ---------- */

/* ----------- Media Queries for Skills ---------- */
@media screen and (max-width: 650px) {
  .box-skills-html {
    max-height: 100px;
    max-width: 100px;
    margin-bottom: 60px;
  }
  .box-skills-css {
    max-height: 100px;
    max-width: 100px;
  }
  .box-skills-js {
    max-height: 100px;
    max-width: 100px;
  }
  .box-skills-bootstrap {
    max-height: 100px;
    max-width: 100px;
  }
  .box-skills-angular {
    max-height: 100px;
    max-width: 100px;
  }
  .box-skills-angular .rotate-text-angular1-1 {
    font-size: 0.9em;
  }
  .box-skills-angular:hover .rotate-text-angular2 {
    margin-top: 80px;
    font-size: 0.9em;
  }
  .box-skills-react {
    max-height: 100px;
    max-width: 100px;
  }
  .box-skills-react:hover .rotate-text-react2 {
    margin-top: 142px;
    font-size: 0.9em;
  }

  .box-skills-react:hover .rotate-text-react1 {
    margin-top: -27px;
    font-size: 0.9em;
  }

  .square {
    height: 80px;
    width: 100px;
  }
  .center-square {
    padding: 0px;
  }
  .square > p {
    margin-top: 27px;
    font-size: 1rem;
  }
}
